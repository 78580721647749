import React, { useEffect } from "react";

const Blog = () => {
  const blogPosts = [
    {
      title: "Mastering JavaScript",
      description:
        "JavaScript is the backbone of modern web development. Learn about its powerful features, frameworks, and best practices.",
      icon: "💻",
      link: "#",
    },
    {
      title: "Getting Started with React",
      description:
        "React is a powerful library for building user interfaces. Discover its core concepts and why it's so popular.",
      icon: "⚛️",
      link: "#",
    },
    {
      title: "Exploring Tailwind CSS",
      description:
        "Tailwind CSS is a utility-first CSS framework for building stunning and responsive designs quickly.",
      icon: "🎨",
      link: "#",
    },
    {
      title: "Diving into Node.js",
      description:
        "Node.js is a server-side JavaScript runtime environment. Learn how to build scalable and efficient server applications.",
      icon: "🌐",
      link: "#",
    },
    {
      title: "Understanding TypeScript",
      description:
        "TypeScript is a typed superset of JavaScript. Find out why it's becoming a standard for large-scale projects.",
      icon: "📘",
      link: "#",
    },
    {
      title: "Building APIs with Express.js",
      description:
        "Express.js is a minimalist framework for building powerful APIs. Learn how to get started with it.",
      icon: "🔗",
      link: "#",
    },
  ];
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <section className="bg-white lg:pb-16 py-10 lg:px-8 px-4">
      {/* Heading */}
      <div className="text-center mb-16 mt-0" data-aos="fade-in">
        <h2 className="text-4xl font-bold mt-5 text-gray-800 mb-5 relative inline-block">
          Blog &<span className="text-blue-500"> Insights</span>
        </h2>
        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
          Discover the latest trends, guides, and tips on development and
          technology.
        </p>
      </div>

      {/* Cards Section */}
      <div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 max-w-6xl mx-auto px-5"
        data-aos="fade-up-left"
      >
        {blogPosts.map((post, index) => (
          <div
            key={index}
            className="border border-blue-500 shadow-box shadow-blue-200 rounded-lg overflow-hidden transform hover:scale-105 hover:shadow-xl transition duration-300 flex flex-col"
          >
            {/* Icon Container */}
            <div className="flex items-center justify-center h-40 bg-gray-900 text-white text-6xl">
              {post.icon}
            </div>
            {/* Card Content */}
            <div className="flex-grow p-6 flex flex-col">
              <h3 className="text-xl font-semibold text-gray-800 mb-3 hover:text-blue-500 transition-colors">
                {post.title}
              </h3>
              <p className="text-gray-600 mb-4 flex-grow">{post.description}</p>
              <a
                href={post.link}
                className="mt-auto self-center border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white px-6 py-2 rounded-full text-sm font-medium transition"
              >
                Read More →
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Blog;
