import React from "react";
import { useNavigate } from "react-router-dom";

const WhyChooseUs = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-900 py-12 px-9 md:px-12">
      <div className="lg:mx-28" data-aos="zoom-in">
        <div className="px-4 sm:px-8 md:px-16">
          <div className="relative mb-4 text-center lg:text-left">
            <span className="absolute left-0 top-0 w-2 h-2 rounded-full bg-blue-600 mt-2 hidden md:block"></span>
            <span className="ml-4 text-sm text-blue-500 uppercase tracking-wider">
              Why Us?
            </span>
          </div>
          <h2 className="text-3xl font-bold mb-4 text-white text-center lg:text-left">
            Why Choose <span className="text-blue-500">MicraSquare?</span>
          </h2>
          <p className="text-white mb-12 text-center lg:text-left">
            MicraSquare has established a proven track record with numerous
            satisfied clients and a high success rate, ensuring confidence in
            the services provided. Our experienced team offers a comprehensive
            range of IT solutions tailored to meet your needs, including
            software development, web development, server management, data
            analytics, cloud services, and adaptable app development. In
            essence, MicraSquare serves as a one-stop solution for all your
            enterprise technology demands.
          </p>
        </div>

        {/* Discuss Project Section */}
        <div className="flex flex-col md:flex-row justify-center lg:justify-start items-center px-4 sm:px-8 md:px-16">
          <div className="mb-6 md:mb-0 md:w-2/3 text-center lg:text-left">
            <div className="relative mb-2">
              <span className="absolute left-0 top-0 w-2 h-2 rounded-full bg-blue-600 mt-2 hidden md:block"></span>
              <span className="ml-4 text-sm text-blue-500 uppercase tracking-wider">
                Let's Discuss
              </span>
            </div>
            <h2 className="text-3xl font-extrabold text-white">
              About Your <span className="text-blue-500">Next Project</span>
            </h2>
          </div>
          <div className="mt-5 md:mt-0">
            <button
              onClick={() => navigate("/contact-us")}
              className="px-6 py-3 border rounded-lg border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white transition-colors duration-300"
            >
              Get In Touch
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
