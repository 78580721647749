import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function ContactUs() {
  const navigate = useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_ylc6q8f", "template_xkbwg6r", form.current, {
        publicKey: "cAa0mKHImLuv_Km9r",
      })
      .then(
        (result) => {
          toast.success("Email sent sucessfully!");
          console.log(result.text);
          console.log("SUCCESS!");
          form.current.reset();
        },
        (error) => {
          toast.error("Something went wrong!");
          console.log("FAILED...", error.text);
        }
      );
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <>
      <section className="bg-white lg:pb-16 mt-10">
        <div className=" sm:mx-8 lg:mx-28 px-8 text-center ">
          <div data-aos="fade-in">
            {/* Title */}
            <h2 className="text-3xl font-bold text-gray-800">
              Get in <span className="text-blue-500">touch</span>
            </h2>
            <p className="text-gray-600 mt-4">
              Ready to help your company scale faster? Let’s chat about how we
              can help.
            </p>
          </div>
          {/* Contact Options */}
          <div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 lg:mt-32"
            data-aos="zoom-in"
          >
            {/* Chat to Sales */}
            <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-box transition-shadow border border-blue-500 shadow-blue-200">
              <div className="text-blue-500 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="w-8 h-8 mx-auto"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c.96 0 1.89-.13 2.77-.36l5.36 1.33c.82.2 1.61-.59 1.41-1.41l-1.33-5.36C21.87 13.89 22 12.96 22 12c0-5.52-4.48-10-10-10zm0 2c4.42 0 8 3.58 8 8 0 .91-.15 1.78-.43 2.59L19.8 20l-4.64-1.16C13.8 19.85 12.91 20 12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8z" />
                </svg>
              </div>
              <h3 className="text-lg font-medium text-gray-800">
                Email to support
              </h3>
              <p className="text-gray-600 mt-2">Speak to our team.</p>
              <button
                className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                onClick={() =>
                  (window.location.href =
                    "mailto:info.micrasquare@gmail.com?subject=Support Inquiry&body=Hello, I need help with...")
                }
              >
                Email to support
              </button>
            </div>

            {/* Chat to Support */}
            <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-box transition-shadow border border-blue-500 shadow-blue-200">
              <div className="text-blue-600 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="w-8 h-8 mx-auto"
                >
                  <path d="M12 2a10 10 0 100 20 10 10 0 000-20zM4 12a8 8 0 0116 0 8 8 0 01-16 0zm9-3h-2v6h2V9zm0 8h-2v-2h2v2z" />
                </svg>
              </div>
              <h3 className="text-lg font-medium text-gray-800">
                Chat to support
              </h3>
              <p className="text-gray-600 mt-2">We’re here to help.</p>
              <button
                className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                onClick={() =>
                  window.open("https://wa.me/917201813023", "_blank")
                }
              >
                Chat to support
              </button>
            </div>

            {/* Visit Us */}
            <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-box transition-shadow border border-blue-500 shadow-blue-200">
              <div className="text-blue-600 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="w-8 h-8 mx-auto"
                >
                  <path d="M12 2C8.14 2 5 5.14 5 9c0 1.38.47 2.63 1.27 3.63l5.56 6.9c.38.47 1.11.47 1.49 0l5.56-6.9C18.53 11.63 19 10.38 19 9c0-3.86-3.14-7-7-7zm0 10c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z" />
                </svg>
              </div>
              <h3 className="text-lg font-medium text-gray-800">Visit us</h3>
              <p className="text-gray-600 mt-2">Visit our office HQ.</p>
              <button
                className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.9319555633!2d72.87456117549506!3d21.2345466804668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fb58c0cf2f1%3A0xd325073d564fad37!2sMicraSquare!5e0!3m2!1sen!2sin!4v1732774616677!5m2!1sen!2sin")
                }
              >
                Get directions
              </button>
            </div>

            {/* Call Us */}
            <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-box transition-shadow border border-blue-500 shadow-blue-200">
              <div className="text-blue-600 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="w-8 h-8 mx-auto"
                >
                  <path d="M6.62 10.79a15.053 15.053 0 006.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.38 2.33.59 3.57.59.55 0 1 .45 1 1v3.5c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1H6c.55 0 1 .45 1 1 0 1.24.21 2.45.59 3.57.12.35.04.74-.24 1.02l-2.2 2.2z" />
                </svg>
              </div>
              <h3 className="text-lg font-medium text-gray-800">Call us</h3>
              <p className="text-gray-600 mt-2">Mon-Fri from 8am to 5pm.</p>
              <button
                className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                onClick={() => (window.location.href = "tel:+917201813023")}
              >
                Call our team
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-16 px-8">
        <div className=" sm:mx-8 lg:mx-28 grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Section - Form */}
          <div data-aos="fade-right">
            {/* Heading */}
            <h2 className="text-3xl font-bold text-gray-800">
              Message <span className="text-blue-500">us</span>
            </h2>
            <p className="mt-2 text-gray-600">
              We'll get back to you within 24 hours.
            </p>

            {/* Form */}

            <form ref={form} onSubmit={sendEmail} className="mt-2 space-y-4">
              <input
                type="text"
                name="user_name"
                placeholder="Your Name"
                className="w-full p-3 border border-gray-300 rounded-md"
              />
              <input
                type="email"
                name="user_email"
                placeholder="Your Email"
                className="w-full p-3 border border-gray-300 rounded-md"
              />
              <textarea
                name="message"
                placeholder="Your Message"
                rows="4"
                className="w-full p-3 border border-gray-300 rounded-md"
              />
              <button
                type="submit"
                className="w-full py-3 bg-blue-600 text-white rounded-md hover:bg-blue-500"
              >
                Send Message
              </button>
            </form>
          </div>

          {/* Right Section - Contact Info */}
          <div className="w-full h-[400px]" data-aos="fade-left">
            <div className="w-full h-[400px]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.9319555633!2d72.87456117549506!3d21.2345466804668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fb58c0cf2f1%3A0xd325073d564fad37!2sMicraSquare!5e0!3m2!1sen!2sin!4v1732774616677!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowFullScreen=""
                loading="lazy"
                className="border-0 rounded-lg shadow-md"
                title="Location Map"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
