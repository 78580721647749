import React, { useEffect } from "react";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    // <div className="bg-gray-50 min-h-screen py-10 px-6 md:px-20">
    //   <div className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg p-8">
    //     <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6">
    //       Privacy Policy
    //     </h1>
    //     <p className="text-gray-600 leading-relaxed mb-6">
    //       At <span className="font-semibold">[Your InfoTech Company]</span>,
    //       your privacy is of utmost importance to us. This Privacy Policy
    //       outlines the types of personal information we collect, how we use it,
    //       and the measures we take to protect it. By using our website, you
    //       agree to the practices described in this policy.
    //     </p>

    //     <h2 className="text-2xl font-semibold text-gray-800 mb-4">
    //       1. Information We Collect
    //     </h2>
    //     <ul className="list-disc pl-6 text-gray-600 mb-6">
    //       <li>
    //         <span className="font-semibold">Personal Information:</span> Name,
    //         email address, phone number, and other contact details provided by
    //         you.
    //       </li>
    //       <li>
    //         <span className="font-semibold">Technical Information:</span> IP
    //         address, browser type, device information, and website usage data.
    //       </li>
    //       <li>
    //         <span className="font-semibold">Cookies:</span> Data collected
    //         through cookies and similar tracking technologies to enhance user
    //         experience.
    //       </li>
    //     </ul>

    //     <h2 className="text-2xl font-semibold text-gray-800 mb-4">
    //       2. How We Use Your Information
    //     </h2>
    //     <p className="text-gray-600 mb-6">
    //       The information we collect is used for:
    //     </p>
    //     <ul className="list-disc pl-6 text-gray-600 mb-6">
    //       <li>Improving our website and services.</li>
    //       <li>Providing personalized user experiences.</li>
    //       <li>Responding to your inquiries and providing support.</li>
    //       <li>
    //         Sending promotional updates and marketing materials (with your
    //         consent).
    //       </li>
    //     </ul>

    //     <h2 className="text-2xl font-semibold text-gray-800 mb-4">
    //       3. Sharing Your Information
    //     </h2>
    //     <p className="text-gray-600 mb-6">
    //       We do not sell, trade, or rent your personal information to third
    //       parties. However, we may share information with:
    //     </p>
    //     <ul className="list-disc pl-6 text-gray-600 mb-6">
    //       <li>Service providers assisting us in website operations.</li>
    //       <li>Law enforcement or regulatory bodies if required by law.</li>
    //     </ul>

    //     <h2 className="text-2xl font-semibold text-gray-800 mb-4">
    //       4. Data Security
    //     </h2>
    //     <p className="text-gray-600 mb-6">
    //       We employ advanced security measures to protect your data from
    //       unauthorized access, alteration, or destruction. However, no system is
    //       100% secure, and we cannot guarantee absolute security.
    //     </p>

    //     <h2 className="text-2xl font-semibold text-gray-800 mb-4">
    //       5. Your Choices
    //     </h2>
    //     <p className="text-gray-600 mb-6">You have the right to:</p>
    //     <ul className="list-disc pl-6 text-gray-600 mb-6">
    //       <li>Access and update your personal information.</li>
    //       <li>Opt-out of marketing communications at any time.</li>
    //       <li>
    //         Request the deletion of your data, subject to legal obligations.
    //       </li>
    //     </ul>

    //     <h2 className="text-2xl font-semibold text-gray-800 mb-4">
    //       6. Updates to this Privacy Policy
    //     </h2>
    //     <p className="text-gray-600 mb-6">
    //       We may update this Privacy Policy periodically to reflect changes in
    //       our practices. We encourage you to review this page regularly to stay
    //       informed.
    //     </p>

    //     <h2 className="text-2xl font-semibold text-gray-800 mb-4">
    //       7. Contact Us
    //     </h2>
    //     <p className="text-gray-600 mb-6">
    //       If you have any questions about this Privacy Policy or our data
    //       practices, please contact us at:
    //     </p>
    //     <ul className="list-disc pl-6 text-gray-600 mb-6">
    //       <li>
    //         Email:{" "}
    //         <a
    //           href="mailto:support@yourcompany.com"
    //           className="text-blue-600 underline"
    //         >
    //           support@yourcompany.com
    //         </a>
    //       </li>
    //       <li>Phone: +1 (234) 567-890</li>
    //       <li>Address: 1234 InfoTech Way, Tech City, TC 56789</li>
    //     </ul>

    //     <p className="text-gray-500 text-sm">Effective Date: [Insert Date]</p>
    //   </div>
    // </div>
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-2xl md:text-3xl font-bold text-gray-800">
        We are currently working on this!
      </h1>
      <p className="mt-4 text-gray-600 text-center">
        Our team is hard at work to bring you the best experience. Stay tuned!
      </p>
    </div>
  );
}

export default PrivacyPolicy;
