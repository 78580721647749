import React, { useState, useEffect } from "react";

const testimonials = [
  // Employee Testimonials
  {
    id: 1,
    text: "Working with the team at MicraSquare has been an incredible experience. The work environment fosters creativity and collaboration, making it a great place to grow professionally.",
    name: "Emily Carter",
    position: "Software Engineer",
    image: "https://randomuser.me/api/portraits/women/45.jpg",
  },
  {
    id: 2,
    text: "I've been with MicraSquare for 3 years, and the growth opportunities have been outstanding. The leadership team is supportive, and I’ve developed my skills tremendously.",
    name: "David Wilson",
    position: "Project Manager",
    image: "https://randomuser.me/api/portraits/men/68.jpg",
  },
  {
    id: 3,
    text: "The work culture at MicraSquare is fantastic. The company encourages personal development and teamwork. It's a pleasure to work alongside such talented and motivated people.",
    name: "Sophie Thomas",
    position: "UX/UI Designer",
    image: "https://randomuser.me/api/portraits/women/78.jpg",
  },
  // {
  //   id: 4,
  //   text: "I’ve been part of some amazing projects here at MicraSquare. The company pushes boundaries with innovative ideas, and I’m always challenged to do my best work.",
  //   name: "Alex Johnson",
  //   position: "DevOps Engineer",
  //   image: "https://randomuser.me/api/portraits/men/21.jpg",
  // },
  // {
  //   id: 5,
  //   text: "Working at MicraSquare has given me the opportunity to work with cutting-edge technology. The team's dedication and passion for technology are truly inspiring.",
  //   name: "Lily Green",
  //   position: "Data Analyst",
  //   image: "https://randomuser.me/api/portraits/women/62.jpg",
  // },

  // Client Testimonials
  {
    id: 6,
    text: "MicraSquare transformed our IT infrastructure, streamlining our processes and improving efficiency. We’re more productive than ever thanks to their exceptional service.",
    name: "Michael Adams",
    position: "CTO, Tech Innovators Inc.",
    image: "https://randomuser.me/api/portraits/men/50.jpg",
  },
  {
    id: 7,
    text: "Their team is highly skilled and responsive. They understand the technicalities of our business and always deliver solutions that exceed expectations.",
    name: "Sarah Williams",
    position: "Founder, GreenTech Solutions",
    image: "https://randomuser.me/api/portraits/women/31.jpg",
  },
  // {
  //   id: 8,
  //   text: "We’ve worked with several IT firms, but none compare to the professionalism and expertise of MicraSquare. They’ve been instrumental in growing our business.",
  //   name: "Robert Martin",
  //   position: "Director of Operations, Global Enterprises",
  //   image: "https://randomuser.me/api/portraits/men/92.jpg",
  // },
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to move to the next testimonial
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to move to the previous testimonial
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  // Set up auto-sliding every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000); // Change testimonial every 5 seconds

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);

  const currentTestimonial = testimonials[currentIndex];

  return (
    <div className="flex flex-col items-center justify-center text-gray-800 p-8 py-16 rounded-md max-w-xl mx-auto min-h-[350px]">
      <div
        data-aos="zoom-out"
        className="flex flex-col items-center justify-center text-center space-y-6"
      >
        {/* Title Section */}
        <h4 className="text-blue-500 uppercase tracking-wide text-sm">
          Our Happy Clients
        </h4>
        <h2 className="text-3xl font-bold">What Clients Say?</h2>

        {/* Testimonial Text */}
        <p className="text-gray-600 leading-relaxed max-w-xl">
          "{currentTestimonial.text}"
        </p>

        {/* Client Info */}
        <div className="flex items-center justify-center">
          <img
            src={currentTestimonial.image}
            alt={currentTestimonial.name}
            className="w-16 h-16 rounded-full mr-4 border-4 border-blue-500"
          />
          <div>
            <h5 className="text-lg font-semibold">{currentTestimonial.name}</h5>
            <p className="text-sm text-gray-500">
              {currentTestimonial.position}
            </p>
          </div>
        </div>

        {/* Dots Navigation */}
        <div className="flex space-x-2">
          {testimonials.map((testimonial, index) => (
            <button
              key={testimonial.id}
              className={`w-3 h-3 rounded-full ${
                currentIndex === index ? "bg-blue-500" : "bg-gray-300"
              } transition-all duration-300`}
              onClick={() => setCurrentIndex(index)}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
