import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AllRoutes from "./utils/routes";
import "aos/dist/aos.css"; // Import AOS CSS
import AOS from "aos";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000, // Animation duration (optional)
    });
  }, []);

  return (
    <div className="app-container flex flex-col overflow-x-hidden">
      <ToastContainer />
      <Navbar className="fixed top-0 left-0 w-full z-10" />
      <main className="content-container flex-1 mt-[4rem]">
        <AllRoutes />
      </main>
      <Footer />
    </div>
  );
}

export default App;
