import React, { useEffect } from "react";

function Terms_Condition() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-2xl md:text-3xl font-bold text-gray-800">
        We are currently working on this!
      </h1>
      <p className="mt-4 text-gray-600 text-center">
        Our team is hard at work to bring you the best experience. Stay tuned!
      </p>
    </div>
  );
}

export default Terms_Condition;
