export const services = [
  {
    id: 1,
    title: "Software Development",
    description: [
      "Prototyping and Wireframing: Creating design mockups and prototypes.",
      "Custom Website Design and Development: Responsive, user-friendly, and visually appealing websites tailored to client requirements.",
      "Content Management Systems (CMS): Expertise in platforms like WordPress, Joomla, and Drupal.",
      "eCommerce Solutions: Developing eCommerce platforms using Shopify, WooCommerce, Magento, or custom solutions.",
      "Web Applications: Building scalable web-based applications using technologies like React, Angular, Vue.js, Node.js, and Python.",
      "SEO and Website Maintenance: Search engine optimization and ongoing website updates.",
      "Custom Software Development: End-to-end development of bespoke software tailored to business needs.",
      "SaaS Development: Building Software as a Service (SaaS) products.",
      "Legacy Modernization: Upgrading old systems to modern architectures.",
      "Process Digitization: Helping businesses transition from manual to digital workflows.",
    ],
  },
  {
    id: 2,
    title: "Mobile Application Development",
    description: [
      "Native App Development: Building apps for iOS (Swift) and Android (Kotlin/Java) platforms.",
      "Cross-Platform App Development: Using frameworks like React Native, Flutter, and Xamarin for cost-effective and efficient development.",
      "Progressive Web Apps (PWAs): Hybrid apps that combine web and mobile experiences.",
      "Mobile App Maintenance: Ongoing support and updates for mobile applications.",
    ],
  },
  {
    id: 3,
    title: "Startup-Focused Services",
    description: [
      "Prototyping and Wireframing: Creating design mockups and prototypes.",
      "MVP Development: Building Minimum Viable Products quickly and cost-effectively.",
      "Scaling Solutions: Helping startups scale their applications and infrastructure.",
      "Technical Consulting: Offering guidance on technology stack, architecture, and development best practices.",
    ],
  },
  {
    id: 4,
    title: "Artificial Intelligence",
    description: [
      "Artificial Intelligence: Implementing Generative AI models and business application (e.g. chat bot) for the end user applications.",
      "Big Data Analytics: Managing and analyzing large datasets for insights and decision-making.",
    ],
  },
  {
    id: 5,
    title: "Infrastructure Management",
    description: [
      "Cloud Setup and Migration: Assistance with cloud adoption and migration to platforms like AWS, Azure, and Google Cloud.",
      "DevOps Services: CI/CD pipeline setup, infrastructure automation, and container orchestration using Kubernetes and Docker.",
      "Monitoring and Maintenance: Continuous monitoring, troubleshooting, and optimizing cloud environments.",
      "24/7 Support: Round-the-clock support for IT systems, software, and applications.",
      "Data Backup and Recovery: Ensuring business continuity through robust data management.",
    ],
  },
  {
    id: 6,
    title: "Outsourcing and Team Augmentation",
    description: [
      "Dedicated Development Teams: Offering skilled professionals as an extension of in-house teams.",
      "Project-Based Engagement: Delivering turnkey projects on a fixed-cost or time-and-material basis.",
    ],
  },
];
