import React, { useEffect, useRef, useState } from "react";
import reactjs from "../assets/img/React.png";
import nextjs from "../assets/img/Next.js.png";
import vuejs from "../assets/img/Vue.js.png";
import html from "../assets/img/HTML5.png";
import tailwind from "../assets/img/CSS3.png";
import angular from "../assets/img/AngularJS.png";
import nodejs from "../assets/img/Node.js.png";
import python from "../assets/img/Python.png";
import java from "../assets/img/Java.png";
import php from "../assets/img/PHP.png";
import laravel from "../assets/img/Laravel.png";
import sql from "../assets/img/sql.png";
import postsql from "../assets/img/PostgresSQL.png";
import mongodb from "../assets/img/MongoDB.png";
import flutter from "../assets/img/Flutter.png";
import kotlin from "../assets/img/Kotlin.png";
import aws from "../assets/img/AWS (1).png";
import azure from "../assets/img/Azure.png";
import gcloud from "../assets/img/Google Cloud.png";
import nifty from "../assets/img/nifty.png";
import slack from "../assets/img/Slack.png";
import jira from "../assets/img/Jira.png";
import drupal from "../assets/img/Drupal.png";
import wix from "../assets/img/wix.png";
import shopify from "../assets/img/sshopify.png";
import wordpress from "../assets/img/WordPress.png";
import notion from "../assets/img/notion.png";
import skype from "../assets/img/skype.png";
import mariaDB from "../assets/img/MariaDB.png";

const TechSection = () => {
  const [selectedCategory, setSelectedCategory] = useState("Frontend");
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    if (slider) {
      let scrollAmount = 0;
      const maxScroll = slider.scrollWidth - slider.clientWidth;

      const autoScroll = setInterval(() => {
        scrollAmount += 2; // Adjust scroll speed
        slider.scrollLeft = scrollAmount;

        if (scrollAmount >= maxScroll) {
          scrollAmount = 0; // Reset scroll to start
        }
      }, 100); // Adjust speed by changing the interval time

      return () => clearInterval(autoScroll);
    }
  }, []);

  const technologies = [
    { name: "HTML", imgSrc: html, category: "Frontend" },
    { name: "CSS", imgSrc: tailwind, category: "Frontend" },
    { name: "React.js", imgSrc: reactjs, category: "Frontend" },
    { name: "Next.js", imgSrc: nextjs, category: "Frontend" },
    {
      name: "Vue.js",
      imgSrc: vuejs,
      category: "Frontend",
    },

    { name: "Angular", imgSrc: angular, category: "Frontend" },

    { name: "Node.js", imgSrc: nodejs, category: "Backend" },
    { name: "Python", imgSrc: python, category: "Backend" },
    { name: "Laravel", imgSrc: laravel, category: "Backend" },
    { name: "Java", imgSrc: java, category: "Backend" },

    // { name: "Php", imgSrc: php, category: "Backend" },
    { name: "Sql", imgSrc: sql, category: "Backend" },
    // { name: "MongoDB", imgSrc: mongodb, category: "Backend" },

    { name: "Flutter", imgSrc: flutter, category: "Mobile" },
    {
      name: "React Native",
      imgSrc: reactjs,
      category: "Mobile",
    },
    { name: "Kotlin", imgSrc: kotlin, category: "Mobile" },

    { name: "AWS", imgSrc: aws, category: "DevOps & Cloud" },
    { name: "Azure", imgSrc: azure, category: "DevOps & Cloud" },
    { name: "Google Cloud", imgSrc: gcloud, category: "DevOps & Cloud" },

    { name: "MongoDB", imgSrc: mongodb, category: "Database" },
    {
      name: "PostgresSQL",
      imgSrc: postsql,
      category: "Database",
    },
    { name: "SQL", imgSrc: sql, category: "Database" },
    { name: "MariaDB", imgSrc: mariaDB, category: "Database" },

    { name: "Jira", imgSrc: jira, category: "Project Manager" },
    {
      name: "Slack",
      imgSrc: slack,
      category: "Project Manager",
    },
    { name: "Nifty", imgSrc: nifty, category: "Project Manager" },
    { name: "Skype", imgSrc: skype, category: "Project Manager" },
    { name: "Notion", imgSrc: notion, category: "Project Manager" },

    { name: "WordPress", imgSrc: wordpress, category: "CMS" },
    { name: "Drupal", imgSrc: drupal, category: "CMS" },
    { name: "Shopify", imgSrc: shopify, category: "CMS" },
    { name: "Wix", imgSrc: wix, category: "CMS" },
  ];

  // Filter technologies based on the selected category
  const filteredTechnologies = technologies.filter(
    (tech) => tech.category === selectedCategory
  );

  return (
    <div className="bg-white py-12">
      <div data-aos="flip-left">
        <div className="text-center mb-8">
          <h3 className="text-blue-500 font-bold text-sm tracking-widest uppercase">
            Best Features
          </h3>
          <h2 className="text-4xl font-extrabold mt-2">Our Technology</h2>
        </div>
        <div
          ref={sliderRef}
          className="flex justify-center items-center overflow-x-auto mb-4 space-x-4 mx-8 sm:mx-16 lg:mx-24"
        >
          {[
            "Frontend",
            "Backend",
            "Mobile",
            "DevOps & Cloud",
            "Database",
            "Project Manager",
            "CMS",
          ].map((category) => (
            <span
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 flex-shrink-0 cursor-pointer ${
                selectedCategory === category
                  ? "text-blue-600 border-b-2 border-blue-600"
                  : "text-gray-500 hover:text-blue-600"
              }`}
            >
              {category}
            </span>
          ))}
        </div>

        <div
          ref={sliderRef}
          className="flex flex-nowrap overflow-x-auto space-x-6 px-6 mx-8 sm:mx-16 lg:mx-28 mt-10 scrollbar-hide"
        >
          {filteredTechnologies.map((tech, index) => (
            <div key={index} className="text-center flex-shrink-0 w-32 sm:w-40">
              <img
                src={tech.imgSrc}
                alt={tech.name}
                className="mx-auto mb-2 w-auto max-w-full h-12 max-h-16 mix-blend-darken"
              />
              <p className="text-gray-800">{tech.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechSection;
