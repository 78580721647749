import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { services } from "../components/ServicesData";

const ServiceCards = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const handleLearnMore = (id) => {
    navigate(`/service/${id}`);
  };

  return (
    <section className="py-12 px-6">
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold text-gray-800">
          Our <span className="text-blue-500">Services</span>
        </h2>
        <p className="text-gray-600 mt-2">
          Explore a wide range of professional solutions tailored to meet your
          business needs.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
        {services.map((service) => (
          <div
            key={service.id}
            className="bg-gradient-to-br from-white via-gray-100 to-gray-200 rounded-lg shadow-md hover:shadow-2xl transition-shadow transform hover:-translate-y-2 p-6 relative group"
            style={{ height: "320px" }}
          >
            <div className="relative z-10 h-full flex flex-col justify-between">
              <div>
                <h3 className="text-2xl font-bold text-gray-800 mb-4 group-hover:text-blue-600 transition-colors">
                  {service.title}
                </h3>
                <ul className="text-gray-700 text-sm list-disc pl-5 space-y-2 overflow-hidden max-h-28">
                  {service.description.slice(0, 3).map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </div>
              <div className="absolute bottom-4 right-4">
                <button
                  onClick={() => handleLearnMore(service.id)}
                  className="px-4 py-2 rounded-lg bg-blue-500 text-white font-medium hover:bg-blue-600 shadow-lg transition-all"
                >
                  Learn More →
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceCards;
