import React, { useEffect, useState } from "react";
import TestimonialCarousel from "../components/TestimonialCarousel";

// Import project images
import acjekpot from "../assets/img/ACEJACKPOT.png";
import AIIMS from "../assets/img/aiims.jpg";
import multiner from "../assets/img/multiner.png";
import wrent from "../assets/img/wrent.png";
import wolfwin from "../assets/img/WOLFWINPLAY.png";
import bettertruck from "../assets/img/better truck.png";
import bpl from "../assets/img/bpl.png";
import upifme from "../assets/img/UPIFYME.png";
import planty from "../assets/img/PLANTY.png";
import vedai from "../assets/img/ved_ai.png";
import cartes from "../assets/img/cartes.png";
import upPolice from "../assets/img/up-police.jpg";
import { useNavigate } from "react-router-dom";

function Portfolio() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [visibleProjects, setVisibleProjects] = useState(6); // Initially show 6 projects
  const navigate = useNavigate();

  // Array of project objects
  const projects = [
    {
      id: 1,
      name: "ACE Jackpot",
      imgSrc: acjekpot,
      description:
        "An innovative jackpot solution designed for seamless user engagement.",
      link: "acejackpot.com",
    },
    {
      id: 2,
      name: "AIIMS",
      imgSrc: AIIMS,
      description:
        "AIIMS is an advanced platform designed for conducting medical examinations and interviews.",
      link: "https://rrp.aiimsexams.ac.in/",
    },
    {
      id: 3,
      name: "Multiner",
      imgSrc: multiner,
      description: "A cutting-edge platform for the cryptocurrency market.",
      link: " ⁠dostguru.com/HTML/multiner",
    },
    {
      id: 4,
      name: "Wrent",
      imgSrc: wrent,
      description: "A visualization tool designed to use for book Tickets.",
      link: "https://wrent.com/",
    },
    {
      id: 5,
      name: "Wolfwin",
      imgSrc: wolfwin,
      description:
        "A gaming platform featuring competitive challenges and rewards.",
      link: "⁠www.wolfwinplay.com",
    },
    {
      id: 6,
      name: "Better Trucks",
      imgSrc: bettertruck,
      description:
        "Better Trucks is a regional, last-mile parcel carrier that specializes in rapid-residential deliveries.",
      link: "https://www.bettertrucks.com/",
    },
    {
      id: 7,
      name: "Upify Me",
      imgSrc: upifme,
      description:
        "A project focused on uplifting personal growth and career development.",
      link: "upifyme.com",
    },
    {
      id: 8,
      name: "Planty",
      imgSrc: planty,
      description: "A project focused on plants and agriculture.",
      link: "dostguru.com/HTML/plantly",
    },
    {
      id: 9,
      name: "Ved AI",
      imgSrc: vedai,
      description:
        "AI-based platform offering cutting-edge solutions for businesses.",
      link: "https://ved-ai.vercel.app/product",
    },
    {
      id: 10,
      name: "UP Police",
      imgSrc: upPolice,
      description:
        "A platform providing affordable and reliable home repair services.",
      link: "",
    },
  ];

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  const handleViewMore = () => {
    setVisibleProjects(projects.length); // Show all projects
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <header className="relative lg:pb-16 py-10 px-8" data-aos="fade-in">
        <div className="mx-auto px-6 text-center mt-5">
          <h1 className="text-3xl md:text-4xl font-bold">
            Our Creative <span className="text-blue-500">Portfolio</span>
          </h1>
          <p className="mt-4 text-gray-600 text-base md:text-lg">
            Showcasing innovative solutions and impactful designs crafted for
            our clients.
          </p>
        </div>
      </header>

      {/* Project Highlights */}
      <section className="px-8 md:px-16 pb-16" data-aos="fade-up-right">
        <div className="max-w-6xl mx-auto">
          <div
            className={`grid gap-8 mt-12 ${
              visibleProjects === 1
                ? "grid-cols-1"
                : "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
            }`}
          >
            {/* Loop through projects array */}
            {projects.slice(0, visibleProjects).map((project) => (
              <div
                key={project.id}
                className="relative group bg-white rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-transform duration-300"
                onClick={() => handleProjectClick(project)}
              >
                <img
                  src={project.imgSrc}
                  alt={project.name}
                  className="w-full h-52 object-contain"
                />

                <div
                  onClick={() => {
                    if (!project.link) return; // Prevent click action if link is empty or undefined
                    window.open(
                      project.link.startsWith("http")
                        ? project.link
                        : `https://${project.link}`,
                      "_blank"
                    );
                  }}
                  className="absolute inset-0 bg-blue-400 bg-opacity-80 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300"
                >
                  <h3 className="text-xl font-semibold text-white cursor-pointer">
                    Visit Site
                  </h3>
                </div>
                <div className="p-4">
                  <p className="text-gray-600 text-sm sm:text-base">
                    {project.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Centering the button */}
          {visibleProjects < projects.length && (
            <div className="flex justify-center items-center mt-14">
              <button
                onClick={handleViewMore}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-500"
              >
                View More Projects
              </button>
            </div>
          )}
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-gray-900 py-20 px-8">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl sm:text-4xl font-bold text-blue-500">
            Let's Build Something Great Together
          </h2>
          <p className="mt-4 text-sm sm:text-base text-white">
            Get in touch with us today and take your project to the next level.
          </p>
          <button
            onClick={() => navigate("/contact-us")}
            className="mt-6 px-6 py-3 bg-white text-blue-700 rounded-lg shadow-md hover:bg-blue-500 hover:text-white border border-blue-500"
          >
            Contact Us
          </button>
        </div>
      </section>

      {/* Testimonials Section (Carousel) */}
      <TestimonialCarousel />
    </div>
  );
}

export default Portfolio;
