import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/img/Untitled-1.png";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isActive = (path) => location.pathname === path;

  const handleLinkClick = () => {
    setIsMenuOpen(false); // Close the menu when a link is clicked
  };

  return (
    <nav
      className={`fixed top-0 w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-white/80 backdrop-blur-md shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between h-16">
        {/* Logo */}
        <div className="flex items-center">
          <img
            src={logo}
            className="h-14 w-auto mt-1 bg-transparent cursor-pointer sm:h-12 md:h-16 lg:h-14"
            onClick={() => navigate("/")}
          />
        </div>

        {/* Navigation Links */}
        <div className="hidden md:flex space-x-6">
          <Link
            to="/"
            className={`text-gray-700 hover:text-blue-500 ${
              isActive("/")
                ? "underline underline-offset-4 decoration-blue-500"
                : ""
            }`}
          >
            Home
          </Link>

          <Link
            to="/about"
            className={`text-gray-700 hover:text-blue-500 ${
              isActive("/about")
                ? "underline underline-offset-4 decoration-blue-500"
                : ""
            }`}
          >
            About
          </Link>
          <Link
            to="/services"
            className={`text-gray-700 hover:text-blue-500 ${
              isActive("/services")
                ? "underline underline-offset-4 decoration-blue-500"
                : ""
            }`}
          >
            Services
          </Link>
          <Link
            to="/portfolio"
            className={`text-gray-700 hover:text-blue-500 ${
              isActive("/portfolio")
                ? "underline underline-offset-4 decoration-blue-500"
                : ""
            }`}
          >
            Portfolio
          </Link>
          <Link
            to="/blog"
            className={`text-gray-700 hover:text-blue-500 ${
              isActive("/blog")
                ? "underline underline-offset-4 decoration-blue-500"
                : ""
            }`}
          >
            Blog
          </Link>
        </div>

        {/* Get in Touch Button */}
        <div className="hidden md:block">
          <button
            onClick={() => navigate("/contact-us")}
            className="border border-blue-500 text-blue-500 px-4 py-2 rounded-md hover:bg-blue-500 hover:text-white transition"
          >
            Get in Touch →
          </button>
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-gray-700 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden flex flex-row justify-center bg-white shadow-md">
          <div className="px-4 py-2 space-y-4 text-center">
            <div>
              <Link
                to="/"
                className={`text-gray-700 hover:text-blue-500 ${
                  isActive("/")
                    ? "underline underline-offset-4 decoration-blue-500"
                    : ""
                }`}
                onClick={handleLinkClick}
              >
                Home
              </Link>
            </div>
            <div>
              <Link
                to="/about"
                className={`text-gray-700 hover:text-blue-500 ${
                  isActive("/about")
                    ? "underline underline-offset-4 decoration-blue-500"
                    : ""
                }`}
                onClick={handleLinkClick}
              >
                About
              </Link>
            </div>
            <div>
              <Link
                to="/services"
                className={`text-gray-700 hover:text-blue-500 ${
                  isActive("/services")
                    ? "underline underline-offset-4 decoration-blue-500"
                    : ""
                }`}
                onClick={handleLinkClick}
              >
                Services
              </Link>
            </div>
            <div>
              <Link
                to="/portfolio"
                className={`text-gray-700 hover:text-blue-500 ${
                  isActive("/portfolio")
                    ? "underline underline-offset-4 decoration-blue-500"
                    : ""
                }`}
                onClick={handleLinkClick}
              >
                Portfolio
              </Link>
            </div>
            <div>
              <Link
                to="/blog"
                className={`text-gray-700 hover:text-blue-500 ${
                  isActive("/blog")
                    ? "underline underline-offset-4 decoration-blue-500"
                    : ""
                }`}
                onClick={handleLinkClick}
              >
                Blog
              </Link>
            </div>
            <div>
              <button
                onClick={() => {
                  handleLinkClick(); // Close the navbar
                  navigate("/contact-us"); // Navigate to the contact-us page
                }}
                className="w-full border border-blue-500 text-blue-500 px-4 py-2 rounded-md hover:bg-blue-500 hover:text-white transition"
              >
                Get in Touch →
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
