import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa"; // Import WhatsApp icon

const WhatsAppButton = () => {
  const [showWhatsApp, setShowWhatsApp] = useState(false);

  // Function to open WhatsApp chat
  const openWhatsappChat = () => {
    window.open("https://wa.me/917201813023", "_blank"); // Replace with your WhatsApp number
  };

  // Show the WhatsApp button when scrolling down
  useEffect(() => {
    const handleScroll = () => {
      setShowWhatsApp(window.scrollY > 200); // Show after scrolling 200px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* WhatsApp Icon Button */}
      {showWhatsApp && (
        <div
          className="fixed bottom-5 right-5 p-4 bg-green-500 text-white rounded-full shadow-lg cursor-pointer hover:bg-green-600 transition-all duration-300"
          style={{
            animation: "upDown 1s infinite alternate", // Apply up-down animation
            zIndex: 9999, // Ensure the button is always on top
          }}
          onClick={openWhatsappChat}
          aria-label="WhatsApp Chat"
        >
          <FaWhatsapp size={32} />
        </div>
      )}

      <style jsx>{`
        /* Up-down animation for WhatsApp icon */
        @keyframes upDown {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(-10px);
          }
        }
      `}</style>
    </>
  );
};

export default WhatsAppButton;
