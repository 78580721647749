import React from "react";
import acjekpot from "../assets/img/ACEJACKPOT.png";
import multiner from "../assets/img/multiner.png";
// import polyskop from "../assets/img/polyskop.jpg";
import wolfwin from "../assets/img/WOLFWINPLAY.png";
import { useNavigate } from "react-router-dom";

const PortfolioSection = () => {
  const navigate = useNavigate();
  return (
    <section className="bg-gray-900 py-12">
      <div
        className="max-w-7xl mx-auto  sm:px-6 px-8 text-center"
        data-aos="fade-top"
      >
        {/* Title */}
        <h3 className="text-blue-500 font-bold text-sm tracking-widest uppercase">
          Best Features
        </h3>
        <h2 className="text-4xl font-extrabold text-white mt-2">
          Our Portfolio.
        </h2>

        {/* Cards */}
        <div className="mt-10 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {/* Single Portfolio Item */}
          <div className="relative bg-white rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition-shadow duration-300">
            <div className="relative w-full md:h-32 lg:h-44">
              <img
                src={acjekpot}
                alt="Breatter"
                className="w-full h-auto max-h-96 object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </div>
            <div className="py-2">
              <h3 className="text-xl font-semibold text-gray-900 hover:text-blue-600 transition-colors duration-300">
                ACE Jackpot
              </h3>
            </div>
          </div>

          <div className="relative bg-white rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition-shadow duration-300">
            <div className="relative w-full  md:h-32 lg:h-44">
              <img
                src={wolfwin}
                alt="Leads"
                className="w-full h-auto max-h-96 object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </div>
            <div className="py-2">
              <h3 className="text-xl font-semibold text-gray-900 hover:text-blue-600 transition-colors duration-300">
                WolfWin Play
              </h3>
            </div>
          </div>

          <div className="relative bg-white rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition-shadow duration-300">
            <div className="relative w-full  md:h-32 lg:h-44">
              <img
                src={multiner}
                alt="Leads"
                className="w-full h-auto max-h-96 object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </div>
            <div className="py-2">
              <h3 className="text-xl font-semibold text-gray-900 hover:text-blue-600 transition-colors duration-300">
                Multiner
              </h3>
            </div>
          </div>
        </div>

        {/* Button */}
        <div className="mt-10">
          <button
            onClick={() => navigate("/portfolio")}
            className="px-8 py-3 border border-blue-500 text-blue-500 font-semibold rounded-lg hover:bg-blue-500 hover:text-white  transition duration-300"
          >
            Explore More
          </button>
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
