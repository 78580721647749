import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Home from "../pages/Home";
import toast from "react-hot-toast";
import About from "../pages/About";
import Blog from "../pages/Blog";
import Portfolio from "../pages/Portfolio";
import Sevices from "../pages/Sevices";
import ContactUs from "../pages/ContactUs";
import ScrollToTop from "../components/ScrollTop";
import Terms_PrivacyPolicy from "../pages/Terms_PrivacyPolicy";
import Terms_Condition from "../pages/Terms_Condition";
import ServiceDetails from "../pages/ServiceDetails";

function AllRoutes() {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Home />
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <About />
            </>
          }
        />

        <Route
          path="/blog"
          element={
            <>
              <Blog />
            </>
          }
        />
        <Route
          path="/services"
          element={
            <>
              <Sevices />
            </>
          }
        />
        <Route
          path="/portfolio"
          element={
            <>
              <Portfolio />
            </>
          }
        />
        <Route
          path="/contact-us"
          element={
            <>
              <ContactUs />
            </>
          }
        />
        <Route
          path="/privacy-pocily"
          element={
            <>
              <Terms_PrivacyPolicy />
            </>
          }
        />
        <Route
          path="/terms-condition"
          element={
            <>
              <Terms_Condition />
            </>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/service/:id" element={<ServiceDetails />} />
      </Routes>
    </div>
  );
}

export default AllRoutes;
