import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { services } from "../components/ServicesData";
const ServiceDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  const { id } = useParams();
  const navigate = useNavigate();

  const service = services.find((service) => service.id === parseInt(id, 10));

  if (!service) {
    return <p className="text-center mt-10">Service not found.</p>;
  }

  return (
    <>
      <div className=" min-h-screen lg:py-10" data-aos="fade-top">
        <div className="relative py-16  lg:px-16 sm:mx-20 lg:mx-32 px-8  mx-auto bg-white rounded-xl   shadow-box shadow-blue-200">
          <button
            onClick={() => navigate(-1)}
            className="absolute top-4 left-4 sm:left-8 md:left-16 px-4 py-2 mt-4 rounded-full bg-gradient-to-r from-gray-100 to-gray-200 text-gray-800 font-medium hover:bg-gradient-to-r hover:from-gray-200 hover:to-gray-300 shadow-md transform hover:-translate-y-1 transition-all"
          >
            ← Back
          </button>

          <div>
            <h1 className="text-5xl font-extrabold text-gray-800 mb-6 leading-snug mt-10">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">
                {service.title}
              </span>
            </h1>
            <p className="text-gray-600 text-lg mb-6">
              Discover comprehensive details about our {service.title} offerings
              below:
            </p>
            <ul className="text-gray-700 text-lg list-disc pl-6 space-y-4">
              {service.description.map((point, index) => (
                <li
                  key={index}
                  className="relative group hover:text-blue-600 transition-colors"
                >
                  <span className="absolute w-2 h-2 bg-blue-500 rounded-full left-[-16px] top-2 group-hover:scale-125 transition-transform"></span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetails;
