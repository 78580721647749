import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <>
      <section className="relative bg-white lg:pb-16 pb-10">
        {/* Background Dots */}

        <div
          className="relative  text-left  sm:mx-20 lg:mx-28 px-8 mt-16"
          data-aos="fade-in"
        >
          <h2 className="text-4xl font-bold text-black mb-3">What we do</h2>
          <p className="text-xl text-blue-500 font-semibold mb-4">
            We turn promising ideas into great businesses.
          </p>
          <p className="text-gray-700 leading-relaxed mb-6">
            We typically build enterprise Software as a Service (SaaS) products,
            powered by Generative AI, and target industries which are exposed to
            market, regulatory, or technology discontinuities. We have built
            businesses across Financial Services, Education, Media and
            Marketing, Public Services and Health, and Sports.
          </p>
          <p className="text-gray-700 leading-relaxed">
            Building businesses requires three things: ideas, infrastructure,
            and people. We do this at scale via our innovation ecosystem.
          </p>
        </div>

        <div className="relative mt-16 text-center lg:mx-28">
          <p className="text-5xl mb-4 lg:pr-72" data-aos="fade-right">
            We don't chase{" "}
            <span className="italic font-bold text-blue-500">unicorns</span>
          </p>
          <div className="border-b-2 w-3/4 mx-auto mt-4 border-blue-500 "></div>
          <p className="text-5xl mt-5 lg:pl-72" data-aos="fade-left">
            we build{" "}
            <span className="italic font-bold text-blue-500">gazelles</span>
          </p>
        </div>
      </section>

      <section className="bg-white py-16 px-8  sm:mx-20 lg:mx-28">
        <div className="container mx-auto flex flex-col lg:flex-row items-center">
          {/* Text Content */}
          <div className="lg:w-1/2 mb-8 lg:mb-0" data-aos="fade-right">
            <h2 className="text-4xl font-bold text-black mb-4">
              How do we do it
            </h2>
            <h3 className="text-2xl text-blue-500 font-semibold mb-6">
              Our innovation ecosystem
            </h3>
            <h4 className="text-xl font-semibold text-black mb-4">Ideas</h4>
            <p className="text-gray-700 font-semibold leading-relaxed mb-4">
              We identify new disruptive market themes or opportunities and have
              25+ years of experience in how to prove these commercially—from
              prototyping through to market testing and ultimately scaling
              businesses internationally.
            </p>
            <p className="text-gray-700 leading-relaxed mb-4">
              Through our Emerging Venture Opportunity (EVO) process, we
              systematically turn great ideas into great businesses. Central to
              our approach is the Product Operating Model, emphasizing customer
              centricity and business viability. We are particularly excited
              about the opportunities GenAI presents for transforming existing
              industries and driving innovative product development.
            </p>
            <p className="text-gray-700 leading-relaxed">
              We are committed to innovation, ensuring our emerging ventures
              leverage the latest insights from the Blenheim Chalcot{" "}
              <span className="text-blue-500 font-semibold">
                Centre for GenAI Innovation
              </span>{" "}
              and our Research & Development activities in Mumbai. Our
              experience and industry knowledge, combined with an environment
              where great entrepreneurs aspire to become great leaders, gives us
              a competitive edge.
            </p>
          </div>

          {/* Image */}
          <div className="lg:w-1/2 lg:ml-20 " data-aos="fade-left">
            <img
              src="https://img.freepik.com/free-vector/illustration-social-media-concept_53876-18383.jpg"
              alt="How do we do it"
              className="w-full h-auto rounded-xl  object-cover"
            />
          </div>
        </div>
        <div
          data-aos="flip-left"
          className="flex mt-12 flex-col lg:flex-row justify-between items-center bg-blue-50 border border-blue-700 rounded-lg shadow-box shadow-blue-300 px-8 py-8   "
        >
          {/* Left Content */}
          <div className="text-center lg:text-left mb-4 lg:mb-0">
            <h2 className="text-2xl font-bold text-black mb-2">
              Collabrate with Micra<span className="text-blue-500">Square</span>
            </h2>
            <p className="text-gray-600 mb-4">
              Explore our Portfolio and Projects
            </p>
            <button
              onClick={() => navigate("/contact-us")}
              className="bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 flex items-center justify-center lg:justify-start"
            >
              Connect with us <span className="ml-2">&rarr;</span>
            </button>
          </div>

          {/* Right Content (Illustration) */}
          <div className="bg-blue-50 flex justify-center lg:justify-start mt-4 lg:mt-0">
            <img
              src="https://freepngimg.com/thumb/categories/745.png"
              alt="Illustration"
              className="w-40 h-auto"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
