import React, { useEffect, useRef } from "react";
import { FaCloud, FaMobileAlt, FaShieldAlt } from "react-icons/fa";
import { AiOutlinePieChart, AiOutlineCode } from "react-icons/ai";
import { BiBrain } from "react-icons/bi";
import { MdOutlineSupportAgent } from "react-icons/md";

const OurClients = () => {
  const sliderRef = useRef(null);

  const services = [
    {
      id: 1,
      icon: <AiOutlineCode size={40} color="#3b82f6" />,
      title: "Web Development",
    },
    {
      id: 2,
      icon: <FaCloud size={40} color="#3b82f6" />,
      title: "Cloud Services",
    },
    {
      id: 3,
      icon: <BiBrain size={40} color="#3b82f6" />,
      title: "AI Solutions",
    },
    {
      id: 4,
      icon: <AiOutlinePieChart size={40} color="#3b82f6" />,
      title: "Data Analytics",
    },
    {
      id: 5,
      icon: <FaMobileAlt size={40} color="#3b82f6" />,
      title: "Mobile Apps",
    },
    {
      id: 6,
      icon: <FaShieldAlt size={40} color="#3b82f6" />,
      title: "Cybersecurity",
    },
    {
      id: 8,
      icon: <MdOutlineSupportAgent size={40} color="#3b82f6" />,
      title: "IT Consulting",
    },
  ];

  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) return;

    const isMobile = window.innerWidth < 1024; // Check for mobile screens
    if (!isMobile) return;

    let scrollAmount = 0;
    const slideWidth = slider.scrollWidth / services.length;

    const autoSlide = () => {
      scrollAmount += slideWidth / 50; // Adjust speed by changing this value
      if (scrollAmount >= slider.scrollWidth) {
        scrollAmount = 0; // Reset to the beginning when reaching the end
      }
      slider.scrollLeft = scrollAmount;
    };

    const interval = setInterval(autoSlide, 100); // Adjust speed by changing interval

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="py-12 bg-gray-900">
      <div className="mx-auto px-8">
        {/* Title */}
        <h3 className="text-blue-500 font-bold text-sm text-center uppercase tracking-widest">
          Innovative Solutions
        </h3>
        <h2 className="text-4xl font-extrabold text-white text-center mt-2">
          Our Services
        </h2>

        {/* Services */}
        <div
          ref={sliderRef}
          className="flex justify-center items-center py-8 mt-8 space-x-6 lg:mx-28 px-8 lg:grid lg:grid-cols-7 lg:gap-20 overflow-x-auto lg:overflow-visible lg:space-x-0 "
        >
          {services.map((service) => (
            <div
              key={service.id}
              className="flex-shrink-0 lg:flex-shrink flex flex-col items-center text-center rounded-lg p-4 shadow-lg hover:scale-105 transition-transform duration-300"
            >
              <div className="flex justify-center items-center">
                {service.icon}
              </div>
              <h4 className="mt-4 text-white font-semibold">{service.title}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurClients;
