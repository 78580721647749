import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import OurClients from "../components/OurClients";
import LeadingSection from "../components/LeadingSection";
import PortfolioSection from "../components/PortfolioSection";
import TechSection from "../components/TechSection";
import WhyChooseUs from "../components/WhyChooseUs";
import TestimonialCarousel from "../components/TestimonialCarousel";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <div className="">
      <div className="bg-white  pb-10 pt-24 lg:min-h-[100vh] items-center justify-center">
        <div className="my-auto mx-auto flex flex-col md:flex-row items-center gap-10 px-8 ">
          <div
            className="flex-1 text-center md:text-left lg:ml-28"
            data-aos="fade-right"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              We build innovative technology businesses.
            </h1>
            <button className="mt-4 inline-flex items-center px-6 py-2 text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-500 hover:text-white">
              Find out more →
            </button>
          </div>

          <div className="flex-1 lg:mr-28" data-aos="fade-left">
            <img
              src="https://img.freepik.com/premium-vector/internet-network-communication-web-technology-computer-icon-vector-online-website-design_1013341-63914.jpg"
              alt="Technology Business"
              className="rounded-lg w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg up-down-animation"
            />
          </div>
        </div>
      </div>

      <OurClients />

      <LeadingSection />

      <PortfolioSection />

      <TechSection />

      <WhyChooseUs />

      <TestimonialCarousel />
    </div>
  );
};

export default Home;
